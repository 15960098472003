import React, { FunctionComponent, InputHTMLAttributes } from "react";

export interface Props extends InputHTMLAttributes<HTMLInputElement> {
  variant: string;
}

const FormInput: FunctionComponent<Props> = ({
  variant,
  onChange,
  ...inputProps
}) => {
  return (
    <div className="input-wrapper">
      <input
        type="text"
        className={`form-input ${variant}`}
        onChange={(e: any) => onChange?.(e.target.value)}
        {...inputProps}
      />
    </div>
  );
};

export default FormInput;
