import axios from "axios";
import { handleResponse, handleError, handleErrorForPopups } from "./response";

let BASE_URL = "";
if (process.env.REACT_APP_ENV === "PROD") {
  BASE_URL = "https://ops.thesettl.com";
} else {
  BASE_URL = "https://testing7865.thesettl.com";
}

//Request Interceptor to add CSRF token to the request header
axios.interceptors.request.use(
  function (config) {
    //const methods = ["post", "put", "delete"];
    const token = localStorage.getItem("SETTLOWNERAUTHORIZATION");
    if (token !== null && token !== "") {
      config.headers["settlownerauthorization"] = token;
    }

    // if (methods.includes(config.method)) {
    //   config.headers["Content-Type"] = "application/json;charset=UTF-8";
    // }

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

/** @param {string} resource */
const get = (resource, baseUrl) => {
  return axios
    .get(`${baseUrl ? baseUrl : BASE_URL}/${resource}`)
    .then(handleResponse)
    .catch(handleError);
};

/** @param {string} resource */
/** @param {object} model */
const post = (resource, model, baseUrl) => {
  return axios
    .post(`${baseUrl ? baseUrl : BASE_URL}/${resource}`, model)
    .then(handleResponse)
    .catch(handleError);
};

const postForImages = (resource, model, baseUrl) => {
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  return axios
    .post(`${baseUrl ? baseUrl : BASE_URL}/${resource}`, model, config)
    .then(handleResponse)
    .catch(handleError);
};

/** @param {string} resource */
/** @param {object} model */
const postForPopups = (resource, model, baseUrl) => {
  return axios
    .post(`${baseUrl ? baseUrl : BASE_URL}/${resource}`, model)
    .then(handleResponse)
    .catch(handleErrorForPopups);
};

/** @param {string} resource */
/** @param {object} model */
const put = (resource, model, baseUrl) => {
  return axios
    .put(`${baseUrl ? baseUrl : BASE_URL}/${resource}`, model)
    .then(handleResponse)
    .catch(handleError);
};

/** @param {string} resource */
/** @param {string} id */
const remove = (resource, id, baseUrl) => {
  return axios
    .delete(`${baseUrl ? baseUrl : BASE_URL}/${resource}`, id)
    .then(handleResponse)
    .catch(handleError);
};

export const apiProvider = {
  get,
  post,
  postForPopups,
  postForImages,
  put,
  remove,
};
