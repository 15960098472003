import React, { useEffect, useState } from "react";
import { MdMoreVert } from "react-icons/md";
import { BsBell, BsCaretDownFill } from "react-icons/bs";
import logo from "../../assets/images/header/logo.png";
import PropertiesDropdown from "../PropertiesDropdown/PropertiesDropdown";
import Notifications from "../Notifications/Notifications";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { apiProvider } from "../../services/api/provider";
import alertBellImage from "../../assets/images/landing_tab/alerts.svg";
// import alertOpenImage from "../../assets/images/landing_tab/alert_open.svg";

import {
  NOTIFICATIONS_URL,
  GET_PROPERTIES_URL,
} from "../../services/api/constants";

export interface Property {
  name: string;
}

export interface Notification {
  title: string;
  body: string;
}

function Header(props: any) {
  const dispatch = useAppDispatch();
  const propertyId = useAppSelector((state) => state.propertyId);

  const [showPropertyDropdown, setPropertyDropdown] = useState<boolean>(false);
  const [showNotificationDropdown, setNotificationDropdown] = useState<boolean>(
    false
  );
  const [properties, setProperties] = useState<Property[]>([]);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [isNewNotification, setNotificationAlert] = useState<boolean>(false);

  const togglePropertyDropdown = (value: boolean) => {
    setPropertyDropdown(value);
    setNotificationDropdown(false);
    props.hideSidebarForMobile();
  };

  const toggleNotificationDropdown = (value: boolean) => {
    setNotificationDropdown(value);
    setPropertyDropdown(false);
    props.hideSidebarForMobile();
    setNotificationAlert(false);
  };

  const toggleSidebar = () => {
    setNotificationDropdown(false);
    setPropertyDropdown(false);
    props.toggleSidebar();
  };

  useEffect(() => {
    apiProvider.get(GET_PROPERTIES_URL, "").then((resp: any) => {
      setProperties(resp.data.properties);
      if (resp?.data?.properties?.length)
        dispatch({
          type: "CHANGE_PROPERTY",
          data: resp.data.properties[0].id,
        });
    });
  }, [dispatch]);

  useEffect(() => {
    if (propertyId !== "") {
      apiProvider
        .get(`${NOTIFICATIONS_URL}?property=${propertyId}`, "")
        .then((resp: any) => {
          setNotifications(resp.data.notifications);
          const notifs = resp.data.notifications;
          if (notifs.length) {
            setNotificationAlert(true);
            localStorage.setItem("lastNotification", notifs[0].title);
          }
        });
    }
  }, [propertyId]);

  return (
    <div className="header-container">
      <div className="header-logo">
        <img src={logo} alt="" />
      </div>
      <div className="right-menu-container">
        <div className="menu-item">
          <i className="icon-prop-inventory"></i>
        </div>
        <div
          className="menu-item notification-bell-wrapper"
          onClick={() => {
            toggleNotificationDropdown(!showNotificationDropdown);
          }}
        >
          {isNewNotification ? (
            <img src={alertBellImage} alt="New notifications" />
          ) : (
            <BsBell />
          )}
        </div>
        <div
          className="menu-item properties-icon-wrapper"
          onClick={() => {
            togglePropertyDropdown(!showPropertyDropdown);
          }}
        >
          Properties
          <div className="properties-icon">
            <BsCaretDownFill />
          </div>
        </div>
        <div
          className="menu-item mobile-menu"
          onClick={() => {
            toggleSidebar();
          }}
        >
          <MdMoreVert />
        </div>
        {showPropertyDropdown && (
          <PropertiesDropdown
            properties={properties}
            togglePropertyDropdown={togglePropertyDropdown}
          />
        )}
        {showNotificationDropdown && (
          <Notifications
            notifications={notifications}
            toggleNotificationDropdown={toggleNotificationDropdown}
          />
        )}
      </div>
    </div>
  );
}

export default Header;
