import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import OtpInput from "react-otp-input";
import loginImage from "../../assets/images/login/login_cover.webp";
import loginMobileImage from "../../assets/images/login/login_mobile.webp";
import FormLabel from "../../components/FormLabel/FormLabel";
import FormInput from "../../components/FormInput/FormInput";
import Message from "../../components/Message/Message";
import Button from "../../components/Button/Button";
import { apiProvider } from "../../services/api/provider";
import logo from "../../assets/images/header/logo.png";
import {
  RESEND_OTP_URL,
  SEND_OTP_URL,
  VERIFY_OTP_URL,
} from "../../services/api/constants";

function Login() {
  const isMobile = window.innerWidth <= 990;
  const [showOtpInput, setOtpInput] = useState<boolean>(false);
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [otp, setOtp] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [statusType, setStatusType] = useState<string>("");
  const [messageFlag, setMessageFlag] = useState<boolean>(false);
  let history = useHistory();

  function navigateToLandingPage() {
    history.push("/app#/Home");
  }

  const updatePhoneNumber = (value: any) => {
    setMessageFlag(false);
    setPhoneNumber(value);
  };

  const updateOTP = (value: any) => {
    console.log("value", value);
    setOtp(value);
    if (value.length === 6) {
      verifyOTP(value);
    }
  };

  const handleKeyPressForSendOTP = (event: any) => {
    console.log("inside handle Key press", event);
    if (event.key === "Enter") {
      sendOTP();
    }
  };

  const sendOTP = () => {
    const requestBody = { phone: phoneNumber };
    apiProvider
      .post(SEND_OTP_URL, requestBody, "")
      .then(() => {
        setOtpInput(true);
        setMessageFlag(false);
        setMessage("");
      })
      .catch((error) => {
        setMessage(error?.response?.data?.error);
        setStatusType("error");
        setMessageFlag(true);
      });
  };

  const verifyOTP = (value: any) => {
    const requestBody = { phone: phoneNumber, otp: value };
    apiProvider
      .post(VERIFY_OTP_URL, requestBody, "")
      .then((resp) => {
        localStorage.setItem(
          "SETTLOWNERAUTHORIZATION",
          resp.data.SETTLOWNERAUTHORIZATION
        );
        navigateToLandingPage();
      })
      .catch((error) => {
        setMessage(error?.response?.data?.error);
        setStatusType("error");
      })
      .finally(() => {
        setMessageFlag(true);
      });
  };

  const resendOTP = () => {
    const requestBody = { phone: phoneNumber };
    apiProvider
      .post(RESEND_OTP_URL, requestBody, "")
      .then((response) => {
        setMessage(response?.data?.msg);
        setStatusType("success");
      })
      .catch((error) => {
        setMessage(error?.response?.data?.error);
        setStatusType("error");
      })
      .finally(() => {
        setMessageFlag(true);
      });
  };

  const closeMessage = () => {
    setMessageFlag(false);
  };

  const bgImage = isMobile ? loginMobileImage : loginImage;

  useEffect(() => {
    document.title = "Lessor Dashboard";
  }, []);

  return (
    <section>
      <div
        style={{
          backgroundImage: `url(${bgImage})`,
        }}
        className="login-container no-gutters"
      >
        <div className="login-header-container">
          <div className="header-logo">
            <img src={logo} alt="" />
          </div>
        </div>
        <div className="login-form-container no-gutters">
          <div className="login-form">
            {!showOtpInput && (
              <>
                <FormLabel formLabel="Enter your registered mobile number with us" />
                <FormInput
                  variant="light"
                  placeholder="Enter your mobile number"
                  value={phoneNumber}
                  onChange={(value) => {
                    updatePhoneNumber(value);
                  }}
                  onKeyPress={(e: any) => {
                    handleKeyPressForSendOTP(e);
                  }}
                />
                {messageFlag ? (
                  <Message
                    type={statusType}
                    message={message}
                    onClose={closeMessage}
                    style={{ marginBottom: "0rem", padding: "0" }}
                  />
                ) : (
                  <></>
                )}
                <div className="button-wrapper">
                  <Button
                    type="button"
                    variant="primary"
                    style={{
                      marginTop: "3rem",
                    }}
                    buttonText="Continue"
                    onClick={() => {
                      sendOTP();
                    }}
                  />
                </div>
              </>
            )}
            {showOtpInput && (
              <div>
                <FormLabel
                  formLabel={`Enter the OTP sent to your registered mobile number 
                  with us: ${phoneNumber}`}
                />
                <OtpInput
                  value={otp}
                  onChange={updateOTP}
                  numInputs={6}
                  separator={<span style={{ width: "8px" }}></span>}
                  isInputNum={true}
                  shouldAutoFocus={true}
                  inputStyle={{
                    border: "1px solid #bdbdbd",
                    borderRadius: "8px",
                    width: "50px",
                    height: "50px",
                    fontSize: "2.5rem",
                    color: "#000",
                    fontWeight: "400",
                    caretColor: "blue",
                  }}
                  focusStyle={{
                    border: "1px solid #707070",
                    outline: "none",
                  }}
                />
                <div className="resend-otp-link-wrapper">
                  <div>Didn’t receive the OTP?</div>
                  <button
                    type="button"
                    className="resend-otp-link"
                    onClick={() => {
                      resendOTP();
                    }}
                  >
                    Resend OTP
                  </button>
                </div>
                {message.length ? (
                  <Message
                    type={statusType}
                    message={message}
                    onClose={closeMessage}
                    style={{ marginBottom: "2rem", padding: "2rem 4rem" }}
                  />
                ) : (
                  <></>
                )}
                {/* <div className="button-wrapper">
                  <Button
                    type="button"
                    variant="primary"
                    buttonText="Continue"
                    onClick={() => {
                      verifyOTP();
                    }}
                  />
                </div> */}
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Login;
