export function handleResponse(response) {
  if (response.data) {
    return response;
  }
}

export function handleError(error) {
  if (error.data) {
    //notify.error(error.data);
    return Promise.reject(error.data);
  } else if (
    error.response.data["error-string"] &&
    error.response.data["error-string"].length > 0
  ) {
    //notify.error(error.response.data["error-string"]);
  } else {
    //notify.error("The application has encountered an unknown error");
  }

  return Promise.reject(error);
}

export function handleErrorForPopups(error) {
  if (error && error.data) {
    return Promise.reject(error.data);
  } else if (error.response.data["error-string"]) {
    return Promise.reject(error.response.data["error-string"]);
  } else {
    return Promise.reject(error.response.data);
  }
}