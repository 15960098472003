import { useAppDispatch } from "../../redux/hooks";
import { MdOutlineClose } from "react-icons/md";

function PropertiesDropdown(props: any) {
  const dispatch = useAppDispatch();
  const setProperty = (id: any) => {
    localStorage.setItem("propertyId", id);
    dispatch({
      type: "CHANGE_PROPERTY",
      data: id,
    });
    props.togglePropertyDropdown(false);
  };

  return (
    <div className="properties-dropdown-container">
      <div
        className="close-button"
        onClick={() => {
          props.togglePropertyDropdown(false);
        }}
      >
        <MdOutlineClose />
      </div>

      {props.properties.map((property: any, index: number) => {
        return (
          <div
            className="property-item"
            key={index}
            onClick={() => {
              setProperty(property.id);
            }}
          >
            <div className="property-name">{property.name}</div>
            <div className="property-address">{property.layout}</div>
            <div className="occupancy">
              Occupancy: {property.occupied_beds}/{property.total_beds}
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default PropertiesDropdown;
