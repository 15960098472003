import NotFoundImage from "../../assets/images/landing_tab/not_found.png";

function PageNotFound() {
  return (
    <div
      style={{
        backgroundImage: `url(${NotFoundImage})`,
      }}
      className="page-not-found-container no-gutters"
    ></div>
  );
}

export default PageNotFound;
