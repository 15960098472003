import React, { FunctionComponent } from "react";

interface Props {
  buttonText: string;
  variant: string;
  [key: string]: any;
}
const Button: FunctionComponent<Props> = ({
  buttonText,
  variant,
  ...inputProps
}) => {
  return (
    <div className="button-comp-wrapper">
      <button className={`button ${variant}`} {...inputProps}>
        {buttonText}
      </button>
    </div>
  );
};

export default Button;
