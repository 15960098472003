import React from "react";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import Login from "./pages/login/login";
import PrivateRoute from "./helpers/PrivateRoute";
import LandingTab from "./pages/LandingTab/LandingTab";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <Router>
          <div>
            <Switch>
              <Route exact path="/login">
                <Login />
              </Route>
              <PrivateRoute path="/">
                <LandingTab />
              </PrivateRoute>
            </Switch>
          </div>
        </Router>
      </Provider>
    </div>
  );
}

export default App;
