import { Modal, Button } from "react-bootstrap";

function ConfirmLogout(props: any) {
  const { logoutPopupData, showLogoutPopup, logoutUser, cancelLogout } = props;

  return (
    <>
      <Modal
        show={showLogoutPopup}
        dialogClassName="confirm-logout-container"
        onHide={cancelLogout}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title> {logoutPopupData.header} </Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button
            size="lg"
            onClick={(e) => {
              cancelLogout();
            }}
            variant="secondary"
          >
            No
          </Button>
          <Button
            size="lg"
            onClick={(e) => {
              logoutUser();
            }}
            variant="primary"
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ConfirmLogout;
