export const SEND_OTP_URL = "owner/send_otp";
export const RESEND_OTP_URL = "owner/resend_otp";
export const VERIFY_OTP_URL = "owner/otp_verify";
export const GET_PROPERTIES_URL = "owner/get_properties";
export const NOTIFICATIONS_URL = "owner/get_notifications";
export const DASHBOARD_URL = "owner/dashboard";
export const PROPERTY_FINANCIAL_URL = "owner/property_financial";
export const ROOM_DETAILS_URL = "owner/inventory_details";
export const PROPERTY_DETAILS_URL = "owner/property_details";
export const SUPPORT_CENTRE_URL = "owner/support";
