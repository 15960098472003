import { MdOutlineClose } from "react-icons/md";

function Notifications(props: any) {
  return (
    <div className="notifications-container">
      <div
        className="close-button"
        onClick={() => {
          props.toggleNotificationDropdown(false);
        }}
      >
        <MdOutlineClose />
      </div>
      {props.notifications.map((notification: any, index: number) => {
        return (
          <div className="notification-item" key={index}>
            <div className="notification-name">{notification.title}</div>
            <div className="notification-address">{notification.body}</div>
          </div>
        );
      })}
    </div>
  );
}

export default Notifications;
