import React, { useState, Suspense, useEffect } from "react";

import { Container, Row, Col } from "react-bootstrap";
import {
  Route,
  Redirect,
  NavLink,
  HashRouter,
  useLocation,
  useHistory,
  Switch,
} from "react-router-dom";
import Header from "../../components/Header/Header";
import Loader from "../../components/Loader/Loader";
import PageNotFound from "../PageNotFound/PageNotFound";
import ConfirmLogout from "../../components/ConfirmLogout/ConfirmLogout";
import propImage from "../../assets/images/landing_tab/prop-financial.svg";
import { BsPower } from "react-icons/bs";
import IdleTimeOutHandler from "../../components/IdleTimeOut/IdleTimeOutHandler";

//Lazy load each module
const Home = React.lazy(() => import("../Home/Home"));
const PropertyFinancial = React.lazy(
  () => import("../PropertyFinancial/PropertyFinancial")
);
const RoomDetails = React.lazy(() => import("../RoomDetails/RoomDetails"));
const PropertyDetails = React.lazy(
  () => import("../PropertyDetails/PropertyDetails")
);
const SupportCentre = React.lazy(
  () => import("../SupportCentre/SupportCentre")
);

const LandingPage = (props: any) => {
  const isMobile = window.innerWidth <= 990;
  const [showSidebar, setSidebarFlag] = useState<boolean>(true);
  const [activeTab, setActiveTab] = useState<string>("");
  const [showLogoutPopup, setLogoutPopupFlag] = useState<boolean>(false);
  const [logoutPopupData, setLogoutPopupData] = useState<object>({
    header: "",
  });

  const location = useLocation();
  let history = useHistory();

  const logoutUser = () => {
    localStorage.clear();
    history.push("/login");
  };

  const onLogOut = () => {
    const data = { header: "Are you sure you want to log out ?" };
    setLogoutPopupData(data);
    setLogoutPopupFlag(true);
  };

  const cancelLogout = () => {
    setLogoutPopupFlag(false);
  };

  useEffect(() => {
    if (isMobile) {
      setSidebarFlag(false);
    }
  }, [isMobile]);

  useEffect(() => {
    const route = location.hash.slice(2);
    console.log("route", route);
    setActiveTab(route);
  }, [location]);

  useEffect(() => {
    document.title = "Lessor Dashboard";
  }, []);

  const toggleSidebar = () => {
    setSidebarFlag(!showSidebar);
  };

  const hideSidebarForMobile = () => {
    isMobile && setSidebarFlag(false);
  };

  return (
    <>
      <Header
        toggleSidebar={toggleSidebar}
        hideSidebarForMobile={hideSidebarForMobile}
      />
      <Container fluid className="landing-tab-container">
        <HashRouter>
          <Row className="landing-tab-wrapper" noGutters={true}>
            {showSidebar && (
              <Col id="sidebar-container" className="sidebar-container">
                <ul className="header">
                  <li className={`${activeTab === "Home" ? "active-tab" : ""}`}>
                    <NavLink to="/Home" onClick={() => hideSidebarForMobile()}>
                      <i className="icon-home"></i> Home
                    </NavLink>
                  </li>
                  <li
                    className={`${
                      activeTab === "property-financial" ? "active-tab" : ""
                    }`}
                  >
                    <NavLink
                      to="/property-financial"
                      onClick={() => hideSidebarForMobile()}
                    >
                      <img
                        src={propImage}
                        alt=""
                        style={{ width: "11%", marginRight: "1rem" }}
                      />
                      Property Financial
                    </NavLink>
                  </li>
                  <li
                    className={`${
                      activeTab === "room-details" ? "active-tab" : ""
                    }`}
                  >
                    <NavLink
                      to="/room-details"
                      onClick={() => hideSidebarForMobile()}
                    >
                      <i className="icon-prop-inventory"></i> Room Details
                    </NavLink>
                  </li>
                  <li
                    className={`${
                      activeTab === "property-details" ? "active-tab" : ""
                    }`}
                  >
                    <NavLink
                      to="/property-details"
                      onClick={() => hideSidebarForMobile()}
                    >
                      <i className="icon-prop-details"></i> Property Details
                    </NavLink>
                  </li>
                  <li
                    className={`${
                      activeTab === "support-centre" ? "active-tab" : ""
                    }`}
                  >
                    <NavLink
                      to="/support-centre"
                      onClick={() => hideSidebarForMobile()}
                    >
                      <i className="icon-support"></i> Support Centre
                    </NavLink>
                  </li>
                  <li className="logout-container">
                    <div
                      className="logout"
                      onClick={() => {
                        hideSidebarForMobile();
                        onLogOut();
                      }}
                    >
                      <BsPower /> Logout
                    </div>
                  </li>
                </ul>
              </Col>
            )}
            <Col id="page-content-wrapper" className="page-content-wrapper">
              <div className="content">
                <Suspense fallback={<Loader />}>
                  <Switch>
                    <Route exact path="/">
                      <Redirect to="/Home" />
                    </Route>
                    <Route exact path="/Home" component={Home} />
                    <Route
                      exact
                      path="/property-financial"
                      component={PropertyFinancial}
                    />
                    <Route
                      exact
                      path="/property-details"
                      component={PropertyDetails}
                    />
                    <Route
                      exact
                      path="/support-centre"
                      component={SupportCentre}
                    />
                    <Route exact path="/room-details" component={RoomDetails} />
                    <Route component={PageNotFound} />
                  </Switch>
                </Suspense>
              </div>
            </Col>
          </Row>
        </HashRouter>
      </Container>
      <IdleTimeOutHandler onLogout={logoutUser} />
      <ConfirmLogout
        logoutPopupData={logoutPopupData}
        showLogoutPopup={showLogoutPopup}
        logoutUser={logoutUser}
        cancelLogout={cancelLogout}
      />
    </>
  );
};
export default LandingPage;
