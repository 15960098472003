import React from "react";
import loaderImage from "../../assets/images/landing_tab/loader.gif";
//if needed give it absolute position via css and raise z index
const Loader = (props: any) => {
  return (
    <div className="loader-container">
      <div className="loader-image">
        <img src={loaderImage} alt="Loading..." />
        <div className="loading-text">Loading...</div>
      </div>
    </div>
  );
};

export default Loader;
