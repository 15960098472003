import React, { FunctionComponent } from 'react';

export interface Props {
  formLabel: string;
  [key: string]: any;
}

const FormLabel: FunctionComponent<Props> = ({
  formLabel = '',
  ...inputProps
}) => {
  return (
    <label className="form-label" {...inputProps}>
      {formLabel}:
    </label>
  );
};

export default FormLabel;
