import React, { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

export const IdleTimeOutModal = ({
  showModal,
  handleContinue,
  handleLogout,
}) => {
  useEffect(() => {
    let timer = setTimeout(() => {
      handleLogout();
    }, 30000);
    return () => {
      console.log("clearing timeout in modla");
      clearTimeout(timer);
    };
  }, []);

  const onContinue = () => {
    console.log("clearing timeout on continue");
    handleContinue();
  };

  return (
    <Modal
      dialogClassName="idle-timeout-container"
      show={showModal}
      onHide={handleContinue}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>You Have Been Idle !</Modal.Title>
      </Modal.Header>
      <Modal.Body>Your session is Timed Out. You want to stay ?</Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={handleLogout}>
          Logout
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            onContinue();
          }}
        >
          Continue Session
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
