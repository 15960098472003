import { MdOutlineClose } from "react-icons/md";

function Message(props: any) {
  const { type, message, onClose, ...inputProps } = props;
  return (
    <div
      className={`message ${
        type === "error" ? "error-message" : "success-message"
      }`}
      {...inputProps}
    >
      {message}
      {type !== "error" ? (
        <div
          className="close-wrapper"
          onClick={() => {
            onClose();
          }}
        >
          <MdOutlineClose />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default Message;
